// Personnelgraph variable overrides
$font-family-sans-serif:"Barlow", sans-serif;
$body-bg: #ffffff;
$white: #ffffff;
$bg-light: #f5f5f5;
$bg-dark: #445056;
$body-color: #263238;
$grid-gutter-width: 30px;
$primary: #047ce6;
$secondary: #263238;
$success: #00f260;
$alert: #ff9e00;
$awaiting: #a3a7aa;
$notice: #d5645c;
$theme-colors: (
  "primary": #047ce6,
  "secondary": #263238,
  "success": #00f260
);
// Bootstrap and its default variables
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import '~material-design-icons/iconfont/material-icons.css';

// Bootstrap Overrides
@import "fonts";
@import "mixins";
@import "navigation";
@import "content";
@import "icons";
