body {
  background: $bg-light;
}

.header-stats {
  background: $bg-dark;
  .results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 1rem 5rem;
    text-align: center;
    article {
      @include sm {
        margin: 2rem 0;
      }
    }
  }
  h1 {
    font-size: 4rem;
    letter-spacing: 0.2rem;
  }

  h4 {
    color: $white;
    letter-spacing: 0.03rem;
    font-weight: 400;
  }
  .btn-link {
    color: $awaiting;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.15rem;

    &:hover,
    &:active,
    &:focus {
      color: $white;
    }
  }
}
.btn-support {
  margin: 0rem 1rem 0.5rem 0rem;
  @include sm {
    margin: 1rem 0rem;
  }
}
.breadcrumb {
  background: none;
  border-bottom: 1px solid lighten($awaiting, 20%);
  padding-bottom: 1rem;
  @include sm {
    display: none;
  }
}
.clear-colorize {
  color: $success;
}
.alert-colorize {
  color: $alert;
}
.awaiting-colorize {
  color: $awaiting;
}

.button-action {
  margin: 0 auto;
  padding: 3rem;
}
.btn {
  @include sm {
    @include btn-block;
  }
}

.list-box {
  border-top: 3px solid $success;
  background: $white;
  @include shadow;
}
.intro-text {
  width: 70%;
  h5 {
    line-height: 1.8rem;
  }
  @include lg {
    width: 100%;
  }
}
.table-section {
  width: 80%;
  margin: 0 auto;
  @include md {
    width: 90%;
  }
  th {
    vertical-align: middle;
    border: none;
    letter-spacing: 0.03rem;
  }
  td {
    vertical-align: middle;
  }
}
.list-group-pagination {
  justify-content: center;
  padding: 2rem;
}
.icon-group {
  display: flex;
  align-items: center;
  img {
    width: 50px;
    min-width: 50px;
  }
}
.results-link {
  font-size: 1.1rem;
  font-weight: 500;
}

.card-box {
  border-top: 3px solid $success;
  padding: 3rem;
  @include shadow;
  @include sm {
    padding: 0.5rem;
  }
}
.card-box-dark {
  padding: 3rem;
  @include shadow;
  @include sm {
    padding: 0.5rem;
  }
}

.results-component {
  h5 {
    font-weight: 700;
    border-bottom: 2px solid $body-color;
    padding-bottom: 0.5rem;
  }
  .list-group-item {
    font-weight: 700;
    background: none;
    border: none;
  }
  span {
    font-weight: 400;
  }
  .subject-info {
    padding: 2rem 3rem 2rem 2rem;
    border: 2px solid $body-color;
  }
}

.box {
  padding: 0.5rem;
  margin: 0;
  &.title-box-primary {
    background: lighten($primary, 37%);
  }

  &.title-box-secondary {
    background: lighten($primary, 47%);
  }
  &.title-box-tertiary {
    background: lighten($alert, 37%);
  }
  &.title-box-quaternary {
    background: lighten($awaiting, 20%);
  }
  &.title-box-quinary {
    background: lighten($alert, 37%);
  }
}
.inline-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.header-info {
  font-size: 1.3rem;
  background: $white;
  @include shadow;
  padding: 1rem;
}
.subject-full-name {
  text-align: center;
  padding: 1rem;
}
.subject-summary {
  th {
    border: none;
  }
}
.modal-content {
  padding: 2rem;
  .close {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 0.7;
  }
}
.custom-ui{
  text-align: center;
  background: $white;
  @include shadow;
  padding: 4rem;
  border-radius: 4px;
  h1{
    margin-top: .5rem;
  }
}
#react-confirm-alert>.react-confirm-alert-overlay{
  background: rgba(0, 0, 0, 0.7) !important;
}

.subject-cannot-edit-info {
  text-align: center;
  margin-top: 1rem;
}

.page-item {
  cursor: pointer;
}

.notice {
  background: $notice;
  color: $bg-dark;
  text-align: center;
  border-radius: 10px;
}

.notice-success {
  background: $success;
  color: $bg-dark;
  text-align: center;
  border-radius: 10px;
}

.notice-alert {
  background: $alert;
  color: $bg-dark;
  text-align: center;
  border-radius: 10px;
}